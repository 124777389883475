<template>
  <Dialog :name="name" width="560px" class="add-culture">
    <template #title>
      <div>{{ getTitle }} культуры</div>
    </template>
    <div>
      <el-form
        ref="formAddCulture"
        :model="formAddCulture"
        :rules="rules"
        label-width="150px"
        @submit.native.prevent
      >
        <el-form-item label="Наименование" prop="name">
          <el-input
            v-model="formAddCulture.name"
            placeholder="Укажите наименование кульуры"
            size="medium"
            class="formAddCulture__cultureName"
          />
        </el-form-item>

        <el-form-item v-if="!isMobile" label="Год урожая" prop="harvest_year">
          <el-date-picker
            v-model="formAddCulture['harvest_year']"
            picker-options
            type="year"
            size="medium"
            placeholder="Укажите год"
            :class="'w-100p'"
          />
        </el-form-item>

        <div v-else class="formItem__mobileMod">
          <div class="formItem__mobileMod-label">
            <span>Год урожая</span>
          </div>

          <IqSelectDateForm
            v-model="formAddCulture['harvest_year']"
            prop="harvest_year"
            picker-options
            type="year"
            size="medium"
            placeholder="Укажите год"
            class="formItem__mobileMod-picker"
            format="dd.MM.yyyy"
            :icon="true"
            :prefix="inputPrefix"
          />
        </div>

        <div :class="buttonWidth">
          <iq-button
            class="addCulture__button"
            :loading="isShowLoader"
            @onClick="handleCloseDialog"
          >
            {{ getTextBtn }}
          </iq-button>
        </div>
      </el-form>
    </div>
  </Dialog>
</template>

<script>
import { CONTROL_TABLE_CULTURE_ADD_CULTURE } from '@/constants/dialogs'
import {
  GET_IS_LOADING_CULTURE,
  POST_CULTURE,
  PUT_CULTURE,
} from '@/views/control/store/actions'
import {
  GET_IS_MOBILE,
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_CURRENT_ID_SLUG,
} from '@/store/actions'
import { GET_YEAR } from '../../../../../../../constants/date'
import { getTime } from '../../../../../../../core'
import { mapActions, mapGetters } from 'vuex'
import { ruleFormAddCulture } from './data/dialogAddCulture'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '../../../../../../ui/components/buttons/IqButton'
import IqSelectDateForm from '@/views/ui/components/select/IqSelectDateForm'

export default {
  name: 'DialogAddCulture',
  components: { Dialog, IqButton, IqSelectDateForm },
  props: { params: { type: Object, default: () => ({}) } },
  data() {
    return {
      name: CONTROL_TABLE_CULTURE_ADD_CULTURE,
      formAddCulture: { name: '', harvest_year: '' },
      rules: ruleFormAddCulture,
      isStepTerminal: false,
    }
  },
  computed: {
    ...mapGetters({
      isShowLoader: GET_IS_LOADING_CULTURE,
      terminalList: GET_TERMINAL_CURRENT_ID,
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
      currentTerminalId: GET_TERMINAL_CURRENT_ID,
      isMobile: GET_IS_MOBILE,
    }),

    dialogData() {
      return this.getDialog(this.name).data || {}
    },
    isEditCulture() {
      return Boolean(this.dialogData?.id)
    },
    getTitle() {
      return this.isEditCulture ? 'Изменение' : 'Добавление'
    },
    getTextBtn() {
      return this.isEditCulture ? 'Обновить' : 'Добавить'
    },
    buttonWidth() {
      return this.isMobile ? 'mobileWidthButton' : 'displayWidthButton'
    },
    inputPrefix() {
      return this.isMobile ? 'none' : ''
    },
  },
  watch: {
    terminalSlug: {
      handler(value) {
        'step' === value
          ? (this.isStepTerminal = true)
          : (this.isStepTerminal = false)
      },
      deep: true,
      immediate: true,
    },

    dialogData() {
      this.setFormFields()

      if (this.isEditCulture) {
        this.setFormFields(this.dialogData.name)
      }
    },
  },
  methods: {
    ...mapActions({ culturePost: POST_CULTURE, culturePut: PUT_CULTURE }),
    handleCloseDialog() {
      this.$refs.formAddCulture.validate(valid => {
        if (valid) {
          const callback = this.onSuccess
          const params = this.params
          const requestData = {
            id: this.dialogData.id,
            ...this.formAddCulture,
            ...{
              harvest_year: getTime(
                this.formAddCulture['harvest_year'],
                GET_YEAR,
              ),
            },
          }

          if (this.isEditCulture) {
            this.culturePut({ requestData, callback })
          } else {
            this.culturePost({ requestData, callback, params })
          }
        }
      })
    },

    setFormFields(name = '') {
      this.formAddCulture.name = name
    },
    onSuccess() {
      this.setDialog({ name: this.name })
      this.setFormFields()
    },
  },
}
</script>

<style lang="sass">
.add-culture
  .el-form-item__label::before
    display: none
  .displayWidthButton
    display: flex
    justify-content: flex-end
  .mobileWidthButton
    display: flex
    justify-content: space-between
    .addCulture__button
      width: 100%
  .formAddCulture__cultureName
    width: 100%
    @media (max-width: 450px)
      .el-input__inner
        height: 40px
        padding-left: 12px
  .formItem__mobileMod
    &-label
      margin-bottom: 10px
    &-picker
      width: 100%
      .el-form-item__content
          .el-input--suffix .el-input__inner
            padding-left: 12px
            height: 40px
</style>
