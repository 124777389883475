var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      staticClass: "add-culture",
      attrs: { name: _vm.name, width: "560px" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v(_vm._s(_vm.getTitle) + " культуры")])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "formAddCulture",
              attrs: {
                model: _vm.formAddCulture,
                rules: _vm.rules,
                "label-width": "150px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Наименование", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "formAddCulture__cultureName",
                    attrs: {
                      placeholder: "Укажите наименование кульуры",
                      size: "medium",
                    },
                    model: {
                      value: _vm.formAddCulture.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formAddCulture, "name", $$v)
                      },
                      expression: "formAddCulture.name",
                    },
                  }),
                ],
                1
              ),
              !_vm.isMobile
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Год урожая", prop: "harvest_year" } },
                    [
                      _c("el-date-picker", {
                        class: "w-100p",
                        attrs: {
                          "picker-options": "",
                          type: "year",
                          size: "medium",
                          placeholder: "Укажите год",
                        },
                        model: {
                          value: _vm.formAddCulture["harvest_year"],
                          callback: function ($$v) {
                            _vm.$set(_vm.formAddCulture, "harvest_year", $$v)
                          },
                          expression: "formAddCulture['harvest_year']",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "formItem__mobileMod" },
                    [
                      _c("div", { staticClass: "formItem__mobileMod-label" }, [
                        _c("span", [_vm._v("Год урожая")]),
                      ]),
                      _c("IqSelectDateForm", {
                        staticClass: "formItem__mobileMod-picker",
                        attrs: {
                          prop: "harvest_year",
                          "picker-options": "",
                          type: "year",
                          size: "medium",
                          placeholder: "Укажите год",
                          format: "dd.MM.yyyy",
                          icon: true,
                          prefix: _vm.inputPrefix,
                        },
                        model: {
                          value: _vm.formAddCulture["harvest_year"],
                          callback: function ($$v) {
                            _vm.$set(_vm.formAddCulture, "harvest_year", $$v)
                          },
                          expression: "formAddCulture['harvest_year']",
                        },
                      }),
                    ],
                    1
                  ),
              _c(
                "div",
                { class: _vm.buttonWidth },
                [
                  _c(
                    "iq-button",
                    {
                      staticClass: "addCulture__button",
                      attrs: { loading: _vm.isShowLoader },
                      on: { onClick: _vm.handleCloseDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.getTextBtn) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }